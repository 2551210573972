import React from "react"
//import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "./tutorial.module.scss"

export default function Tutorial({ location }) {
    return (
        <Layout location={location}>
            <SEO
                title="Tutorial"
            />
            <article className={styles.comingSoon}>
                <header>
                    <h1>
                        Tutorial
                    </h1>
                </header>
                <section>
                    <p>Coming soon...</p>
                </section>
            </article>
        </Layout>
    )
}
